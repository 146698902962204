import React from "react";

function About() {
  return (
    <section className="container" id="about">
      <div className="about">
        <h2 className="section--header">About Us</h2>
        <h3 className="section--subheader">
          Just in case you are wondering who we are
        </h3>
        <div className="about--content">
          <div className="about--content__img">&nbsp;</div>
          <div className="about--content__text">
            <p>
              Welcome to our esteemed technological institution, meticulously
              oriented towards delivering excellence in the realms of web
              development, digital transformation, mobile application
              deployment, bespoke software solutions, comprehensive training
              modules, and a spectrum of correlated services.
            </p>
            <p>
              At our core, we specialize in furnishing enterprise-grade
              solutions that traverse the entire journey from conceptualization
              to seamless execution. Our unwavering commitment to innovation and
              precision drives our pursuit of engineering cutting-edge solutions
              that resonate with industry standards and client aspirations
              alike.
            </p>
            <p>
              Our distinguished clientele encompasses a diverse tapestry of
              esteemed entities, including governmental agencies, private
              enterprises, and non-governmental organizations. This privileged
              amalgamation of partners attests to our competence in navigating
              the intricacies of various sectors, thereby solidifying our status
              as a versatile and proficient collaborator.
            </p>
            <p>
              Indulge in an immersive experience with us as we pave the way for
              technological evolution, propelling businesses and organizations
              towards unparalleled pinnacles of success.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
